/* You can add global styles to this file, and also import other style files */

 
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body{font-family: 'Roboto', sans-serif !important;}
.container {max-width: 1200px !important;}
img {max-width: 100%;}
/*****  start Checkbox *****/
.checkbox-box {display: block;position: relative;cursor: pointer;margin-bottom: 0px;}
.checkbox-box span {text-align: left;display: inline-block;letter-spacing: 0.12px;color: #789AAE;padding-left: 31px;font-size: 12px;line-height: 16px; }
.checkbox-box span:after {top: 0px;content: "";width: 20px;height: 20px;background: #FFFFFF;border: 1px solid #9EB5D8;border-radius: 4px;position: absolute;left: 0px;}
.checkbox-box input {position: absolute;width: 20px !important;left: 0px;opacity: 0;z-index: +9999;height: 20px;cursor: pointer;margin: 0px !important;}
.checkbox-box .checkbox-box input[type=checkbox]:checked+span:after {border-color:#9EB5D8;}
.checkbox-box span:before {top: 0px;content: "✔";position: absolute;width: 20px;height: 20px;left: 0px;z-index: 9;margin: 0;opacity: 0;text-align: center;line-height: 25px;font-size: 16px;line-height: 20px;color: #fff;background: #9EB5D8;border-radius: 4px;}
.checkbox-box input[type=checkbox]:checked+span:before {opacity: 1;}
.checkbox-false span:before {content: "\F1398";color: #ff3d60 !important;}
.action-checkbox {display: flex;}
/***** End Checkbox *****/
/*****  start Radio box *****/
.radio_label .radio-box {position: relative;cursor: pointer;margin-bottom: 0px;display: inline-block;}
.radio_label .radio-box span {display: inline-block;padding-left: 30px;letter-spacing: 0.14px;color: #03314B;font-size: 14px;line-height:20px;font-family: 'Roboto', sans-serif;}
.radio_label .radio-box span:after {content: "";width: 20px;height: 20px;display: inline-block;position: absolute;left: 0px;top: 3px;background: #ffff;border-radius: 60px;border: 1px solid #9EB5D8; }
.radio_label .radio-box span:before {content: "";width: 12px;height: 12px;display: inline-block;position: absolute;left: 4px;top:7px;background-color: #9EB5D8;border-radius: 60px;z-index: 1;opacity: 0;}
.radio_label .radio-box input {position: absolute;width: 20px;left: 0px;opacity: 0;z-index: +9999;height: 20px;cursor: pointer;}
.radio_label .radio-box input[type=radio]:checked+span:before {opacity: 1;}
.radio_label .radio-box input[type=radio]:checked+span:after {border-color:#9EB5D8;}
.radio_label .radio-box {margin-right: 15px;margin-bottom: 0px !important;}
.radio_label .radio-box:last-child {margin-right: 0px;}
/***** End Radio kbox *****/

// Card Details
.card-details { background:url(/assets/images/card-bg.png) #FFFFFF;    background-position: center !important; background-size: cover !important;     border-radius: 10px !important;box-shadow: 0px 3px 5px #e6e8f2;border: 1px solid #E6E8F2 !important;}
.card-details .card-body {padding: 0px;}
.card-details .card-title {letter-spacing: 0.25px;color: #162F56;font-size: 20px;line-height: 24px;margin-bottom:0px;border-bottom: 1px solid #DEE2F8;padding: 13px;}
.card-details .tableCard {width: 100%;}
.card-details .table_card {padding: 13px;}
.card-details .tableCard td {letter-spacing: 0px;color: #162F56;font-size: 15px;font-weight: 400;line-height: 24px;padding: 8px 0px;border-bottom:1px solid rgb(222, 226, 248,0.64);}
.card-details .tableCard tr:last-child td {border: 0px;}
.card-details .colon-icon {text-align: end;position: absolute;right: 0px;}
.card-details .left-td {position: relative;}
.card-details .right-td {text-align: end;padding-left: 10px !important;}

h4.card-title {color: #162F56;font-size: 20px;line-height: 24px;}
.dateSelect {outline: none;cursor: pointer;min-width: 155px;background:url(/assets/images/icons/arrow-down.png) #F8F9FF;background-position: calc(100% - 10px); background-repeat: no-repeat; border: 1px solid #D3D7EE;border-radius: 5px;font-size: 15px;font-weight: 600;color: #486AF0;padding:6px 10px;-webkit-appearance: none; appearance: none;}

// table-col
.table-responsive,.tableCard {background: #fff;border-radius: 10px;}
.table-col {width: 100%;}
.table-col thead th {letter-spacing: 0px;color: #162F56;font-size: 17px;}
.table-col th,.table-col td {border-right: 1px solid #DEE2F8;text-align: center;padding: 14px 10px;line-height: 20px;font-size: 15px;}
.table-col td {border-top: 1px solid #DEE2F8;}
.table-col th:first-child,.table-col td:first-child {text-align: left;}
.table-col td:last-child, .table-col th:last-child {border-right: 0px;}
.table-col tr:nth-child(odd) td {background: #F6F8FF;}

// 
.information-icon {top: -2px;position: relative;left: 3px;cursor: pointer;}
.highlight-td td {font-weight: bold;}
.t-head th {border-bottom: 1px solid #DEE2F8;text-align: center !important;color: #8289AA !important;}


// paginate
.pagination {justify-content: flex-end;border-top: 1px solid #DEE2F8;align-items: center;margin: 0;padding: 15px 10px;}
.pagination li a {background: none !important;border: 0px;padding: 0px 4px;font-size: 16px;color: #A1A5BC;display: block;}
.pagination li.active a,.pagination li a:hover {color: #486AF0;}
.pagination .next img {transform: rotate(-90deg);display: block;}
.pagination .prev img {transform: rotate(90deg);display: block;}

 
// Responsive
@media only screen and (max-width:1460px) {
.table-col thead th {font-size: 14px;}
.table-col td {font-size: 14px;}
}

@media only screen and (max-width:1260px) {
  .container {padding:0 30px !important;}
}

@media only screen and (max-width:1140px) {
.menuItem ul li {margin-right: 10px;font-size: 13px;}
// 
.table-responsive .table-col {width: 1220px;}
}
@media only screen and (max-width:999px) {
body:before {content: "";position: absolute;top: 0;left: 0;right: 0;bottom: 0;background: rgba(0,0,0,.5);transition: all .3s ease;opacity: 0;visibility: hidden;z-index: 1;}
body.offcanvas-menu:before {opacity: 1;visibility: visible;z-index: 9;}
.menu-right .navbar-toggler {display: block !important;padding: 0px;border: 0px;margin-right: 20px;}
.menu-right .menuItem {position: fixed;left: 0px;background: #0192fd;margin: 0px !important;width: 250px;height: 100%;top: 0;z-index: 2000;transform: translateX(-110%);box-shadow: -10px 0 20px -10px rgb(0 0 0 / 10%);transition: all .3s ease-in-out;padding: 20px;}
.menu-right .menuItem.show {transform: translateX(0);}
 .menuItem ul li {font-size: 18px !important;width: 100%;padding-bottom: 10px; margin-right: 0px !important;}
.navbar-toggler path {fill: #fff;}
body.offcanvas-menu .login-sign, body.offcanvas-menu .navbar-toggler {opacity: 0.5;}

}

@media only screen and (max-width:510px) {
  .container {padding: 0 15px !important;}
  .login-sign {font-size: 0px !important;}
  header#header {padding: 10px 0px;}
}
